import { createGlobalStyle } from 'styled-components'

// CSS
import './reset.css'
import './font.css'

// Theme
import theme from './index'

if (process.env.NODE_ENV === 'development') console.log('theme', theme)

const GlobalStyles = createGlobalStyle`
html,
body {
   min-height: 100vh;
   background: ${theme.colors.color2.default};
   line-height:1.75;
}

:hover {
   transition: ${theme.transitions.transition1};
}

h1, h2, h3, h4, h5, h6 {
   color: ${({ theme }) => theme.colors.color1.default};
   letter-spacing: 2px;
   text-align: center;
}

h1 {
   font-family: 'Bison Bold';
   font-size: ${({ theme }) => theme.font.fontSizes.h1};
   color: ${({ theme }) => theme.colors.color1.default};
   line-height: 1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h1};
   }
}

h2 {
   font-family: 'Bison DemiBold';
   font-size: ${({ theme }) => theme.font.fontSizes.h2};
   color: ${({ theme }) => theme.colors.color1.default};
   line-height: 1.1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h2};
   }
}

h3 {
   font-family: 'Bison Bold';
   font-size: ${({ theme }) => theme.font.fontSizes.h3};
   color: ${({ theme }) => theme.colors.color1.default};
   line-height: 1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h3};
   }
}

h4 {
   font-family: ${({ theme }) => theme.font.fontFamily.script};
   font-size: ${({ theme }) => theme.font.fontSizes.h4};
   color: ${({ theme }) => theme.colors.color3.dark.d_1};
   line-height: 1.1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h4};
   }
}

h5 {
   font-family: 'Bison DemiBold';
   font-size: ${({ theme }) => theme.font.fontSizes.h5};
   color: ${({ theme }) => theme.colors.color1.default};

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h5};
   }
}

h6 {
   font-size: ${({ theme }) => theme.font.fontSizes.h6};

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h6};
   }
}

p {
   font-family: ${theme.font.fontFamily.body};
   letter-spacing: 1px;
}
`

export default GlobalStyles
