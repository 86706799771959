export const viewports = {
  sm: 600, // ${({ theme }) => theme.breakpoints.sm};
  md: 960, // ${({ theme }) => theme.breakpoints.md};
  lg: 1280, // ${({ theme }) => theme.breakpoints.lg};
  xl: 1920, // ${({ theme }) => theme.breakpoints.xl};
}

const breakpoints = {}

for (const [key, value] of Object.entries(viewports)) {
  breakpoints[key] = `${value}px`
}

export default breakpoints
