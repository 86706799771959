import * as React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

// Context
import DimensionsProvider from './src/context/DimensionsProvider'
import SiteDetailsProvider from './src/context/SiteDetailsProvider'

// Theme
import theme from './src/theme'
import GlobalStyles from './src/theme/GlobalStyles'

function RootWrapper({ element }) {
  return (
    <DimensionsProvider>
      <SiteDetailsProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {element}
        </ThemeProvider>
      </SiteDetailsProvider>
    </DimensionsProvider>
  )
}

RootWrapper.propTypes = {
  element: PropTypes.node.isRequired,
}

export default RootWrapper
