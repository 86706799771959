// https://typescale.com/

const styles = {
  // Perfect Fourth
  mobileSizes: {
    h1: '4.209rem', // 67.34px || font-size: ${({ theme }) => theme.font.mobileSizes.h1};
    h2: '3.157rem', // 50.52px || font-size: ${({ theme }) => theme.font.mobileSizes.h2};
    h3: '2.369rem', // 37.90px || font-size: ${({ theme }) => theme.font.mobileSizes.h3};
    h4: '1.777rem', // 28.43px || font-size: ${({ theme }) => theme.font.mobileSizes.h4};
    h5: '1.333rem', // 21.33px || font-size: ${({ theme }) => theme.font.mobileSizes.h5};
    h6: '1.1rem', // font-size: ${({ theme }) => theme.font.mobileSizes.h6};
  },
  fontSizes: {
    h1: '7.99375rem', // 127.9px || font-size: ${({ theme }) => theme.font.fontSizes.h1};
    h2: '5.65rem', // 90.4px || font-size: ${({ theme }) => theme.font.fontSizes.h2};
    h3: '4rem', // 64px || font-size: ${({ theme }) => theme.font.fontSizes.h3};
    h4: '2.825rem', // 45.2px || font-size: ${({ theme }) => theme.font.fontSizes.h4};
    h5: '2rem', // 32px || font-size: ${({ theme }) => theme.font.fontSizes.h5};
    h6: '1.419rem', // 22.7px || font-size: ${({ theme }) => theme.font.fontSizes.h6};
    base: '1rem', // 16px || font-size: ${({ theme }) => theme.font.fontSizes.base};
    sm: '0.75rem', // 12px || font-size: ${({ theme }) => theme.font.fontSizes.sm};
  },
  fontWeight: {
    black: '900', // font-weight: ${({ theme }) => theme.font.fontWeight.black};
    extraBold: '800', // font-weight: ${({ theme }) => theme.font.fontWeight.extraBold};
    bold: '700', // font-weight: ${({ theme }) => theme.font.fontWeight.bold};
    semiBold: '600', // font-weight: ${({ theme }) => theme.font.fontWeight.semiBold};
    medium: '500', // font-weight: ${({ theme }) => theme.font.fontWeight.medium};
    regular: '400', // font-weight: ${({ theme }) => theme.font.fontWeight.regular};
    light: '300', // font-weight: ${({ theme }) => theme.font.fontWeight.light};
    extraLight: '200', // font-weight: ${({ theme }) => theme.font.fontWeight.extraLight};
    thin: '100', // font-weight: ${({ theme }) => theme.font.fontWeight.thin};
  },
  fontFamily: {
    headers: 'Bison', // font-family: ${({ theme }) => theme.font.fontFamily.headers};
    body: 'Montserrat', // font-family: ${({ theme }) => theme.font.fontFamily.body};
    script: 'Heritage', // font-family: ${({ theme }) => theme.font.fontFamily.script};
  },
}

export default styles
