import breakpoints from './breakpoints'
import colors from './color'
import font from './font'
import spacings from './spacings'
import transitions from './transitions'

const theme = {
  breakpoints,
  font,
  colors,
  spacings,
  transitions,
}

export default theme
