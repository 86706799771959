module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"missing","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smoking Swines","short_name":"Smoking Swines","start_url":"/","background_color":"#F9F6EF","theme_color":"#A02529","display":"minimal-ui","icon":"/opt/build/repo/frontend/src/static/favicon.png","icons":[{"src":"/opt/build/repo/frontend/src/static/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/opt/build/repo/frontend/src/static/android-chrome-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"/opt/build/repo/frontend/src/static/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2c97a9c7182b63069ccf530c83e3c42d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
