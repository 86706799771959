import tinycolor from 'tinycolor2'

export const sep = '_'

const looper = (c, b = true) => {
  const tint = b === true ? 'l' : 'd'
  const base = 16
  const obj = {}

  for (let step = 1; step <= 4; step++) {
    const change =
      b === true
        ? tinycolor(c)
            .lighten(step * base)
            .toString()
        : tinycolor(c)
            .darken(step * base)
            .toString()

    if (change === '#000000' || change === '#ffffff') return obj
    if (obj[`${tint}${sep}${step - 1}`] === change) return obj
    obj[`${tint}${sep}${step}`] = change
  }
  return obj
}

export default function colorizer(color, name = null) {
  return {
    default: color,
    name,
    light: looper(color),
    dark: looper(color, false),
    isLight: tinycolor(color).isLight(),
  }
}
