const spacings = {
  xxSmall: '0.125rem', // 2px || ${({ theme }) => theme.spacings.xxSmall};
  xSmall: '0.25rem', // 4px || ${({ theme }) => theme.spacings.xSmall};
  small: '0.5rem', // 8px || ${({ theme }) => theme.spacings.small};
  normal: '1rem', // 16px || ${({ theme }) => theme.spacings.normal};
  medium: '1.5rem', // 24px || ${({ theme }) => theme.spacings.medium};
  xMedium: '2rem', // 32px || ${({ theme }) => theme.spacings.xMedium};
  xxMedium: '2.5rem', // 40px || ${({ theme }) => theme.spacings.xxMedium};
  xxxMedium: '3rem', // 48px || ${({ theme }) => theme.spacings.xxxMedium};
  large: '3.5rem', // 56px || ${({ theme }) => theme.spacings.large};
  xLarge: '4rem', // 64px || ${({ theme }) => theme.spacings.xLarge};
  xxLarge: '5rem', // 80px || ${({ theme }) => theme.spacings.xxLarge};
  xxxLarge: '6rem', // 96px || ${({ theme }) => theme.spacings.xxxLarge};
  jumbo: '7rem', // 112px || ${({ theme }) => theme.spacings.jumbo};
  xJumbo: '8rem', // 128px || ${({ theme }) => theme.spacings.xJumbo};
  xxJumbo: '9rem', // 144px || ${({ theme }) => theme.spacings.xxJumbo};
  xxxJumbo: '10rem', // 160px || ${({ theme }) => theme.spacings.xxxJumbo};
}

export default spacings
