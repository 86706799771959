import React, { createContext } from 'react'

// Hooks
import useWindowDimensions from '../hooks/useWindowDimensions'

const defaultContext = {
  windowWidth: undefined,
  windowHeight: undefined,
}

export const DimensionsContext = createContext(defaultContext)

export default function DimensionsProvider({ children }) {
  const { windowWidth, windowHeight } = useWindowDimensions()
  if (process.env.NODE_ENV === 'development') console.log({ windowWidth, windowHeight })

  return (
    <DimensionsContext.Provider value={{ windowWidth, windowHeight }}>
      {children}
    </DimensionsContext.Provider>
  )
}
