import colorizer from '../utils/colorizer'

// Generator: https://coolors.co/generate

const colors = {
  black: '#000000', // ${({ theme }) => theme.colors.black};
  color1: colorizer(`#2C2A28`, `Jet`), // ${({ theme }) => theme.colors.color1.default};
  color2: colorizer(`#F9F6EF`, `Floral White`), // ${({ theme }) => theme.colors.color2.default};
  color3: colorizer(`#a02529`, `Auburn`), // ${({ theme }) => theme.colors.color3.default};
  white: '#ffffff', // ${({ theme }) => theme.colors.white};
}

export default colors
